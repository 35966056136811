<template>
  <v-app>
    <!--<pages-core-app-bar />-->

    <pages-core-view />

    <pages-core-footer v-if="client != 'HPE Campus'"/>
  </v-app>
</template>

<script>
  import axios from "axios";
  export default {
    name: 'PagesIndex',
    data() {
      return {
        client: ''
      }
    },
    components: {
      PagesCoreAppBar: () => import('./components/core/AppBar'),
      PagesCoreFooter: () => import('./components/core/Footer'),
      PagesCoreView: () => import('./components/core/View')
    },
    created() {
      this.$vuetify.theme.dark = true
      const _this = this;
      axios
        .get("cms/config")
        .then((response) => {
          _this.client = response.data.Client;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    beforeDestroy() {
      this.$vuetify.theme.dark = false
    }
  }
</script>
